import request from '@/utils/request'


// 获取推送策略
export function getAllStrategy(listquery = {}) {
    return request.post('/strategy/getAll', listquery)
}

// 获取推送策略列表
export function getStrategyList(data) {
    return request({
        url: '/strategy/list',
        method: 'get',
        params: data,
    })
}

// 保存推送策略
export function saveStrategy(data) {
    return request.post('/strategy/save', data)
}

export function saveStrategyPeriod(data) {
    return request.post('/strategy/reset/period', data)
}

// 获取推送策略列表
export function getapIStrategyList(params) {
    return request({
        url: '/exchangeApiKeys/automationStrategyConfigs',
        method: 'get',
        params
    })
}

// 新增策略配置
export function addStrategy(data) {
    return request({
        url: '/exchangeApiKeys/addAutomationStrategyConfig',
        method: 'post',
        data
    })
}

// 更新策略配置
export function updateStrategy(data) {
    return request({
        url: '/exchangeApiKeys/editAutomationStrategyConfig',
        method: 'post',
        data
    })
}

// 删除策略配置
export function deleteStrategy(data) {
    return request({
        url: `/exchangeApiKeys/deleteAutomationStrategyConfig`,
        method: 'post',
        data
    })
}
